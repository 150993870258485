import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';

import MetaTags from '../../../components/MetaTags';

import { theme } from '../../../styles/theme';

import Background from '../../../components/Background';
import Header from '../../../components/Header2';
import HowGroupGiftsWork from '../../../sections/occasion/HowGroupGiftsWork';

import CuratedGifts from '../../../sections/occasion/CuratedGifts';

import GroupCard from '../../../sections/occasion/GroupCard';
import LoveIllume from '../../../sections/occasion/LoveIllume';
import JoinOccasions from '../../../sections/JoinOccasions';
import Footer from '../../../sections/common/FooterWithNav';
import futureCelebrating from '../../../images/occasion/birthday-colleague.png';
import futurePhone from '../../../images/occasion/iPhone12.png';

import data from '../../../sections/occasion/data';
import HeaderSection from '../../../sections/occasion/HeaderSection';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionBirthdayColleague: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Wish your colleague a happy birthday from the entire team with illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={'Group Birthday Gifts for Colleagues'}
          paragraphs={[
            'Lisa’s birthday is next week. You’re scrambling to get everyone in the office to sign her card & pitch in on a gift. Now two coworkers have to leave for a business trip and a third is working from home… Drop the stress and go in on a group gift with illume.',
            'Pick out a fun gift or gift card from illume’s digital marketplace, invite the team to contribute with cash, messages, photos, and more – and then hit send before the big day. Lisa will never see it coming until you hit her inbox with that sweet ray of sunshine :)',
          ]}
          buttonLink={'https://link.illumenotes.com/YmzHM8nCkSXkQUoUA'}
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Birthday for a Colleague',
            },
            phone: {
              image: futurePhone,
              label: 'Birthday for a Colleague Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions 
            joinMessage={'of celebrating birthdays'}
        />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionBirthdayColleague;
